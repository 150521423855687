<template>
    <v-app>
        <dashboard-header></dashboard-header>
        <v-main class="main">
            <v-container class="py-6">
                <slot></slot>
            </v-container>
        </v-main>

        <div id="voiceform-container"></div>
    </v-app>
</template>

<script>
import DashboardHeader from './DashboardHeader.vue';
import { voiceform } from '../utils/scriptLoader';
import { Crisp } from "crisp-sdk-web";

export default {
    components: { DashboardHeader },
    name: "empty-layout",
    data: function () {
        return {}
    },
    created() {
        voiceform('side-tab');
    },
    mounted() {
        Crisp.chat.show();
    }
}
</script>

<style lang="scss">
    :root {
        --overflow: unset;
    }
</style>

<style scoped>
.main{
    background-color: #FAFAFA;
}
</style>
