const useTypes = [
    { code: 'research_survey', useType: 'Research Survey'},
    { code: 'request_form', useType: 'Request Form'},
    { code: 'registration_form', useType: 'Registration Form'},
    { code: 'quiz', useType: 'Quiz'},
    { code: 'poll', useType: 'Poll'},
    { code: 'order_form', useType: 'Order Form'},
    { code: 'lead_gen_form', useType: 'Lead Gen Form'},
    { code: 'feedback_form', useType: 'Feedback Form'},
    { code: 'issue_form', useType: 'Issue Form'},
    { code: 'contact_form', useType: 'Contact Form'},
    { code: 'checklist', useType: 'Checklist'},
    { code: 'application_form', useType: 'Application Form'},
    { code: 'other', useType: 'Other'},
]

export default useTypes;