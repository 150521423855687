<template>
    <v-app-bar clipped-left style="border-bottom: 1px solid #0000003B !important;" class="header" height="64" color="primary lighten-5" outlined elevation="0" app>
        <router-link :to="{path: '/'}">
            <img src="https://cdn.voiceform.com/media/blue_logo.svg" style="height: 24px; width: 155.85px" height="24px"/>
        </router-link>
        <v-spacer></v-spacer>
        <div class="d-flex align-center">
            <v-btn v-if="showBilling" :dark="showLimitedTimeOffer" :outlined="!showLimitedTimeOffer" :to="{ name: 'dashboard.upgrade'}" :color="showLimitedTimeOffer?'deep-orange lighten-1':'primary'" elevation="0">{{showLimitedTimeOffer?`Offer expires ${offerExpiresIn}`:"View Plans"}}</v-btn>
            <v-divider v-if="showBilling" class="mx-4 my-2" vertical></v-divider>
            <user-profile-dropdown></user-profile-dropdown>
        </div>
    </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'
import UserProfileDropdown from './UserProfileDropdown.vue'
export default {
    components: { UserProfileDropdown },
    computed:{
        ...mapGetters({
            user: 'auth/user',
            showLimitedTimeOffer: 'auth/showLimitedTimeOffer'
        }),
        showBilling(){
            return !!this.user && this.user.billing
        },
        offerExpiresIn(){
            if(this.showLimitedTimeOffer ){
                return this.user.limited_time_offer && this.$date(this.user.limited_time_offer).fromNow()
            }
            return ''
        }
    }
}
</script>
<style lang="scss" scoped>
.header{
    border-bottom: 1px solid #0000003B !important;
}
</style>