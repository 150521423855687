const DEFAULT_SURVEY = {
    id: null,
    name: "",
    title: "Welcome",
    goodbye_message: "Goodbye",
    top_left_file_path: '',
    top_left_file_url: '',
    welcome_page_file_path: '',
    welcome_page_file_url: '',
    language: '',
    ends_at: null,
    information_to_collect: {
        age: false,
        email: false,
        first_name: false,
        gender: false,
        household_income: false,
        last_name: false,
        location: false,
        phone_number: false,
    },
    settings: {
        is_progressbar_hidden: false,
        localization: {
            start_button_label: "Let's start",
            next: "Next",
            finish: "Finish",
            record: "Record",
            stop: "Stop",
            launch: "Launch me",
            question_error_message: "The question is required.",
            back: "Back"
        },
    },
    background_color: '',
    questions: [],
    style: {
        color: '#000000',
        button: {
            color: '#FFFFFF',
            background: '#000000'
        },
        background: '#FFFFFF'
    },
}

const DEFAULT_QUESTION = {
    multiple_choice_items: [],
    text: null,
    type: null,
    _id: null,
    order: null,
    style: {
        background: null,
        color: null,
    },
    properties: {}
}

export default [
    {
        id: '1',
        title: 'NPS Template',
        subtitle: 'Understand customer loyalty on a deeper level, and measure customer trends over time.',
        image: require('@/assets/img/templates-covers/template-cover-01.png'),
        lazy: require('@/assets/img/templates-covers/template-cover-01-placeholder.png'),
        survey: {
            ...DEFAULT_SURVEY,
            title: "NPS Survey 🙌",
            language: "en-US",
            include_consent: 0,
            include_mic_check: 0,
            goodbye_message: "Thanks for your feedback!",
            include_text_answers: 0,
            name: "NPS Survey Template",
            subtitle: "<p>Let us know what you think of &lt;company name&gt;.</p>",
            include_welcome_page: 1,
            style: {
                color: "#000000",
                button: {
                    color: "#FFFFFF",
                    background: "#0268FF"
                },
                background: "#EBF3FF"
            },
            version: "v2",
            email_notifications: 1,
            use_type: "feedback_form",
            questions: [
                {
                    ...DEFAULT_QUESTION,
                    text: "How likely are you to recommend us to a friend or colleague?",
                    is_optional: 0,
                    type: "net-promoter-score",
                    order: 0,
                },
                {
                    ...DEFAULT_QUESTION,
                    text: "Why did you choose your answer?",
                    is_optional: 0,
                    type: "voice-response",
                    order: 1,
                    enable_text_input: 1,
                    description: "<p>Help us understand your experience.</p>",
                },
                {
                    ...DEFAULT_QUESTION,
                    text: "Is there anything else you'd like our team to know?",
                    is_optional: 0,
                    type: "voice-response",
                    order: 2,
                    enable_text_input: 1,
                    description: "<p>We look forward to actioning your feedback.</p>",
                }
            ]
        }
    },
    {
        id: '2',
        title: 'Case Study Template',
        subtitle: 'Learn how your organization is delivering real value to your customers, so you can better promote it.',
        image: require('@/assets/img/templates-covers/template-cover-02.png'),
        lazy: require('@/assets/img/templates-covers/template-cover-02-placeholder.png'),
        survey: {
            ...DEFAULT_SURVEY,
            title: "Thanks for sharing your thoughts 🎉",
            language: "en-US",
            include_consent: 0,
            include_mic_check: 0,
            goodbye_message: "Thank you!",
            include_text_answers: 0,
            name: "Case Study Template",
            subtitle: "<p>We're excited to profile you in our next case study.</p>",
            include_welcome_page: 1,
            style: {
                color: "#000000",
                button: {
                    color: "#FFFFFF",
                    background: "#0268FF"
                },
                background: "#EBF3FF"
            },
            version: "v2",
            email_notifications: 1,
            use_type: "feedback_form",
            questions: [
                {
                    ...DEFAULT_QUESTION,
                    text: "Tell us about yourself!",
                    is_optional: 0,
                    type: "voice-response",
                    order: 0,
                    enable_text_input: 1,
                    description: "<p>We'd love to know more about your journey into your current role.</p>",
                    properties: {
                        maxRecordTime: 150
                    }
                },
                {
                    ...DEFAULT_QUESTION,
                    text: "Tell us about your current role",
                    is_optional: 0,
                    type: "voice-response",
                    order: 1,
                    enable_text_input: 1,
                    description: "<p>We'd love to know more about the work you do in your current role. </p>",
                    properties: {
                        maxRecordTime: 150
                    }
                },
                {
                    ...DEFAULT_QUESTION,
                    text: "What problem did you have that led you to want to try us?",
                    is_optional: 0,
                    type: "voice-response",
                    order: 2,
                    enable_text_input: 1,
                    properties: {
                        maxRecordTime: 150
                    }
                },
                {
                    ...DEFAULT_QUESTION,
                    text: "How did you go about solving this problem before using us?",
                    is_optional: 0,
                    type: "voice-response",
                    order: 3,
                    enable_text_input: 1,
                    properties: {
                        maxRecordTime: 150
                    }
                },
                {
                    ...DEFAULT_QUESTION,
                    text: "How have we helped you solve this problem?",
                    is_optional: 0,
                    type: "voice-response",
                    order: 4,
                    enable_text_input: 1,
                    properties: {
                        maxRecordTime: 150
                    }
                },
                {
                    ...DEFAULT_QUESTION,
                    text: "How have we changed the way you work?",
                    is_optional: 0,
                    type: "voice-response",
                    order: 5,
                    enable_text_input: 1,
                    properties: {
                        maxRecordTime: 150
                    }
                },
                {
                    ...DEFAULT_QUESTION,
                    text: "How have we quantitatively helped your business?",
                    is_optional: 0,
                    type: "voice-response",
                    order: 6,
                    enable_text_input: 1,
                    description: "<p>Examples could include: time saved, increased revenue or increased conversion.</p>",
                    properties: {
                        maxRecordTime: 150
                    }
                },
                {
                    ...DEFAULT_QUESTION,
                    text: "Anything else to share?",
                    is_optional: 0,
                    type: "voice-response",
                    order: 7,
                    enable_text_input: 1,
                    properties: {
                        maxRecordTime: 150
                    }
                }
            ]
        }
    },
    {
        id: '3',
        title: 'Product Review Template',
        subtitle: 'Pinpoint why customers love your products, and what you can do to improve.',
        image: require('@/assets/img/templates-covers/template-cover-03.png'),
        lazy: require('@/assets/img/templates-covers/template-cover-03-placeholder.png'),
        survey: {
            ...DEFAULT_SURVEY,
            title: "Review your recent product experience! 🙌",
            language: "en-US",
            include_consent: 0,
            include_mic_check: 0,
            goodbye_message: "Thanks for your feedback!",
            include_text_answers: 0,
            name: "Product Review Template",
            subtitle: "<p>We'd love to hear your opinion.</p>",
            include_welcome_page: 1,
            style: {
                color: "#000000",
                button: {
                    color: "#FFFFFF",
                    background: "#0268FF"
                },
                background: "#EBF3FF"
            },
            version: "v2",
            email_notifications: 1,
            use_type: "feedback_form",
            questions: [
                {
                    ...DEFAULT_QUESTION,
                    text: "How would you rate your recent product experience?",
                    is_optional: 0,
                    type: "rating",
                    multiple_choice_items: [
                        {
                            icon: "number",
                            order: 1
                        },
                        {
                            icon: "number",
                            order: 2
                        },
                        {
                            icon: "number",
                            order: 3
                        },
                        {
                            icon: "number",
                            order: 4
                        },
                        {
                            icon: "number",
                            order: 5
                        }
                    ],
                    order: 0,
                    description: "<p>Be as honest as possible.</p>",
                },
                {
                    ...DEFAULT_QUESTION,
                    text: "How likely are you to recommend us to a friend or colleague?",
                    is_optional: 0,
                    type: "net-promoter-score",
                    order: 1
                },
                {
                    ...DEFAULT_QUESTION,
                    text: "Anything else to share about your product experience?",
                    is_optional: 1,
                    type: "voice-response",
                    order: 2,
                    enable_text_input: 1,
                    description: "<p>We look forward to actioning your feedback.</p>"
                },
                {
                    ...DEFAULT_QUESTION,
                    text: "Enter your name",
                    is_optional: 0,
                    type: "name",

                    order: 3
                },
                {
                    ...DEFAULT_QUESTION,
                    text: "Enter your email",
                    is_optional: 0,
                    type: "email",
                    order: 4
                }
            ]
        }
    },
    {
        id: '4',
        title: 'Interview Template',
        subtitle: 'Spotlight amazing stories, and learn about your audience in a whole new way.',
        image: require('@/assets/img/templates-covers/template-cover-04.png'),
        lazy: require('@/assets/img/templates-covers/template-cover-04-placeholder.png'),
        survey: {
            ...DEFAULT_SURVEY,
            title: "Thanks for answering our questions 🎉",
            language: "en-US",
            include_consent: 0,
            include_mic_check: 0,
            goodbye_message: "Thank you!",
            include_text_answers: 0,
            name: "Interview Template",
            subtitle: "<p>We're excited to interview you!</p>",
            include_welcome_page: 1,
            style: {
                color: "#000000",
                button: {
                    color: "#FFFFFF",
                    background: "#0268FF"
                },
                background: "#EBF3FF"
            },
            version: "v2",
            email_notifications: 1,
            use_type: "feedback_form",
            questions: [
                {
                    ...DEFAULT_QUESTION,
                    text: "Tell us about yourself!",
                    is_optional: 0,
                    type: "voice-response",

                    order: 0,
                    enable_text_input: 1,
                    description: "<p>We'd love to know more about your background.</p>",
                },
                {
                    ...DEFAULT_QUESTION,
                    text: "Tell us about your current role",
                    is_optional: 0,
                    type: "voice-response",
                    order: 1,
                    enable_text_input: 1,
                    description: "<p>We'd love to know more about the work you do in your current role. </p>",
                },
                {
                    ...DEFAULT_QUESTION,
                    text: "What experiences have shaped your career?",
                    is_optional: 0,
                    type: "voice-response",
                    order: 2,
                    enable_text_input: 1
                },
                {
                    ...DEFAULT_QUESTION,
                    text: "What advice helped you get to where you are today?",
                    is_optional: 0,
                    type: "voice-response",
                    order: 3,
                    enable_text_input: 1,
                },
                {
                    ...DEFAULT_QUESTION,
                    text: "What is the biggest lesson you've learned recently?",
                    is_optional: 0,
                    type: "voice-response",
                    order: 4,
                    enable_text_input: 1
                },
                {
                    ...DEFAULT_QUESTION,
                    text: "What are you most looking forward to?",
                    is_optional: 0,
                    type: "voice-response",
                    order: 5,
                    enable_text_input: 1,
                },
                {
                    ...DEFAULT_QUESTION,
                    text: "What advice would you give to our audience?",
                    is_optional: 0,
                    type: "voice-response",
                    order: 6,
                    enable_text_input: 1
                }
            ]
        }
    },
    {
        id: '5',
        title: 'Employee Engagement Template',
        subtitle: 'Get the source about how your employees feel, and how you can help them thrive.',
        image: require('@/assets/img/templates-covers/template-cover-05.png'),
        lazy: require('@/assets/img/templates-covers/template-cover-05-placeholder.png'),
        survey: {
            ...DEFAULT_SURVEY,
            title: "Thanks for sharing your thoughts 🙌",
            language: "en-US",
            include_consent: 0,
            include_mic_check: 0,
            goodbye_message: "Thanks for your feedback!",
            include_text_answers: 0,
            name: "Employee Engagement Template",
            subtitle: "<p>We'd love to know what you think about working here.</p>",
            include_welcome_page: 1,
            style: {
                color: "#000000",
                button: {
                    color: "#FFFFFF",
                    background: "#0268FF"
                },
                background: "#EBF3FF"
            },
            version: "v2",
            email_notifications: 1,
            use_type: "feedback_form",
            questions: [
                {
                    ...DEFAULT_QUESTION,
                    text: "Do you feel energized about coming to work?",
                    is_optional: 0,
                    type: "rating",
                    multiple_choice_items: [
                        {
                            icon: "number",
                            order: 1
                        },
                        {
                            icon: "number",
                            order: 2
                        },
                        {
                            icon: "number",
                            order: 3
                        },
                        {
                            icon: "number",
                            order: 4
                        },
                        {
                            icon: "number",
                            order: 5
                        },
                        {
                            icon: "number",
                            order: 6
                        },
                        {
                            icon: "number",
                            order: 7
                        },
                        {
                            icon: "number",
                            order: 8
                        },
                        {
                            icon: "number",
                            order: 9
                        },
                        {
                            icon: "number",
                            order: 10
                        }
                    ],
                    order: 0,
                    description: "<p><br>1 = rarely energized<br><br>10 = highly energized</p>",
                },
                {
                    ...DEFAULT_QUESTION,
                    text: "Could you share more about your previous answer?",
                    is_optional: 0,
                    type: "voice-response",
                    order: 1,
                    enable_text_input: 1,
                },
                {
                    ...DEFAULT_QUESTION,
                    text: "What matters most to you when it comes to your career?",
                    is_optional: 0,
                    type: "voice-response",
                    order: 2,
                    enable_text_input: 1,
                },
                {
                    ...DEFAULT_QUESTION,
                    text: "What values do you look for in a company?",
                    is_optional: 0,
                    type: "voice-response",
                    order: 3,
                    enable_text_input: 1,
                },
                {
                    ...DEFAULT_QUESTION,
                    text: "Is our company aligned to your goals and values?",
                    is_optional: 0,
                    type: "voice-response",
                    order: 4,
                    enable_text_input: 1,
                },
                {
                    ...DEFAULT_QUESTION,
                    text: "What should we do better to help you achieve your goals?",
                    is_optional: 0,
                    type: "voice-response",
                    order: 5,
                    enable_text_input: 1,
                },
                {
                    ...DEFAULT_QUESTION,
                    text: "Do you feel you have everything you need to succeed in your role?",
                    is_optional: 0,
                    type: "voice-response",
                    order: 6,
                    enable_text_input: 1,
                },
                {
                    ...DEFAULT_QUESTION,
                    text: "Are there any tools or resources we can equip you with to thrive in your role?",
                    is_optional: 0,
                    type: "voice-response",
                    order: 7,
                    enable_text_input: 1,
                },
                {
                    ...DEFAULT_QUESTION,
                    text: "Do you feel like you have access to the right mentors and champions?",
                    is_optional: 0,
                    type: "voice-response",
                    order: 8,
                    enable_text_input: 1,
                },
                {
                    ...DEFAULT_QUESTION,
                    text: "Anything else to share?",
                    is_optional: 0,
                    type: "voice-response",
                    order: 9,
                    enable_text_input: 1,
                }
            ]
        }
    },
    {
        id: '6',
        title: 'Lead Capture Template',
        subtitle: 'Get those leads. A fast, effective and highly-converting form.',
        image: require('@/assets/img/templates-covers/template-cover-06.png'),
        lazy: require('@/assets/img/templates-covers/template-cover-06-placeholder.png'),
        survey: {
            ...DEFAULT_SURVEY,
            title: "Let's stay connected 🤝",
            language: "en-US",
            include_consent: 0,
            include_mic_check: 0,
            goodbye_message: "Talk soon!",
            include_text_answers: 0,
            name: "Lead Capture Template",
            subtitle: "<p>We'd love to follow up, and share more resources.</p>",
            include_welcome_page: 1,
            style: {
                color: "#000000",
                button: {
                    color: "#FFFFFF",
                    background: "#0268FF"
                },
                background: "#EBF3FF"
            },
            version: "v2",
            email_notifications: 1,
            use_type: "lead_gen_form",
            questions: [
                {
                    ...DEFAULT_QUESTION,
                    text: "Enter your name",
                    is_optional: 0,
                    type: "name",
                    order: 0,
                },
                {
                    ...DEFAULT_QUESTION,
                    text: "Enter your email",
                    is_optional: 0,
                    type: "email",
                    order: 1,
                },
                {
                    ...DEFAULT_QUESTION,
                    text: "Anything else we should know to best support you?",
                    is_optional: 1,
                    type: "voice-response",
                    order: 2,
                    enable_text_input: 1,
                }
            ]
        }
    },
    {
        id: '7',
        title: 'User Persona Template',
        subtitle: 'Demystify your users, and build connections that help you understand their \'why.\'',
        image: require('@/assets/img/templates-covers/template-cover-07.png'),
        lazy: require('@/assets/img/templates-covers/template-cover-07-placeholder.png'),
        survey: {
            ...DEFAULT_SURVEY,
            title: "We'd love to learn more about you! 😎",
            language: "en-US",
            include_consent: 0,
            include_mic_check: 0,
            goodbye_message: "Thanks for your thoughts!",
            include_text_answers: 0,
            name: "User Persona Template",
            subtitle: "<p>Help us understand how to serve you better.</p>",
            include_welcome_page: 1,
            style: {
                color: "#000000",
                button: {
                    color: "#FFFFFF",
                    background: "#0268FF"
                },
                background: "#EBF3FF"
            },
            version: "v2",
            email_notifications: 1,
            use_type: "research_survey",
            questions: [
                {
                    ...DEFAULT_QUESTION,
                    text: "First off, what brought you to try our product?",
                    is_optional: 0,
                    type: "multiple-choice",
                    multiple_choice_items: [
                        {
                            value: "Business"
                        },
                        {
                            value: "School"
                        },
                        {
                            value: "Personal"
                        }
                    ],
                    order: 0,
                },
                {
                    ...DEFAULT_QUESTION,
                    text: "What problem made you want to try our product",
                    is_optional: 0,
                    type: "voice-response",
                    order: 1,
                    enable_text_input: 1,
                },
                {
                    ...DEFAULT_QUESTION,
                    text: "How is our product delivering value to you?",
                    is_optional: 0,
                    type: "voice-response",
                    order: 2,
                    enable_text_input: 1,
                },
                {
                    ...DEFAULT_QUESTION,
                    text: "Anything else you'd like to tell us?",
                    is_optional: 0,
                    type: "voice-response",
                    order: 3,
                    enable_text_input: 1,
                    description: "<p>Is there any way we can better support you and how you use our product?</p>",
                },
                {
                    ...DEFAULT_QUESTION,
                    text: "We'd love to follow up with you",
                    is_optional: 0,
                    type: "email",
                    order: 4,
                    description: "<p>If that's cool, drop your email below.</p>",
                }
            ]
        }
    },
]
