<template>
    <v-breadcrumbs color="primary" class="px-0" :items="breadcrumbItems"></v-breadcrumbs>
</template>

<script>
export default {
    computed: {
        close() {
            return !window.history.length || this.$route.query && this.$route.query.nav_close
        },
        breadcrumbItems(){
            const items = [
                {   
                    text: 'Home',
                    disabled: false,
                    exact: true,
                    to: {
                        name: 'dashboard.index'
                    }
                }
            ]

            items.push({
                text: this.$route.meta.text,
                to: {
                    name: this.$route.name,
                    params: this.$route.params
                }
            })

            return items
        }
    },
    methods: {
        goBack(){

            if(!this.close && window.history.length){
                this.$router.back()
            }else{
                this.$router.push({
                    path: '/' 
                })
            }
        }
    }
}
</script>