<template>
    <v-card flat rounded class="pa-6">
        <v-form v-if="survey" ref="form" v-model="valid" lazy-validation>

                <div class="text-h5 mb-4">Create New Voiceform</div>
                <v-text-field class="mt-4" label="Form Name" v-model="survey.name" :rules="rules" validate-on-blur outlined clearable
                    dense hide-details="auto"></v-text-field>
                <v-select class="mt-4" label="What are you creating?" hide-details="auto" :items="useTypes" :menu-props="{ bottom: true, offsetY: true }"
                    :rules="rules" validate-on-blur outlined v-model="useTypeSelector" item-text="useType" item-value="code"
                    dense hint="This will help to analyze your voiceform results better" persistent-hint></v-select>
                <v-text-field v-if="isOther" class="mt-4" v-model="survey.use_type" :rules="rules" validate-on-blur
                    label="Describe your use case briefly" outlined clearable dense></v-text-field>
                <div class="d-flex">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="$emit('close')" class="mr-2">
                        Cancel
                    </v-btn>
                    <v-btn :elevation="0" color="primary" :loading="createSurveyLoading" :disabled="createSurveyLoading"
                        @click="handleCreateSurveyClick">
                        Create form
                    </v-btn>
                </div>
        </v-form>
    </v-card>
</template>


<script>
import useTypes from '@/configs/useTypes'
import templates from '../dashboard/voiceform/create/templates';
import { mapActions, mapMutations, mapGetters } from "vuex";
export default {
    data(){
        return {
            useTypes: useTypes,
            valid: true,
            rules: [
                value => !!value || 'Required',
            ],
            createSurveyLoading: false,
        }
    },
    computed:{
        ...mapGetters({
            survey: "survey/survey",
            features: 'auth/features'
        }),
        useTypeSelector: {
            get(){
                if(this.isOther){
                    return 'other'
                }
                return this.survey.use_type
            },
            set(value) {
                this.survey.use_type = value === 'other' ? '' : value
            }
        },
        isOther(){
            if(this.survey.use_type !== null){
                const slicedOptions = this.useTypes.slice(0, this.useTypes.length-1)
                const item = slicedOptions.find(option=> option.code === this.survey.use_type)
                return !item
            }
            return false
        },
    },
    methods: {
        ...mapActions({
            createSurvey: "survey/createSurvey",
            setSurvey: "survey/setSurvey",
        }),
        ...mapMutations({
            setDefaultQuestion: "survey/setDefaultQuestion",
            setDefaultSurvey: "survey/setDefaultSurvey",
            removeCurrentQuestion: "survey/removeCurrentQuestion",
            'showSnackBar': 'showSnackBar'
        }),

        async handleCreateSurveyClick() {
            const valid = this.$refs.form.validate();

            if(!valid) {
                return;
            }

            try {
                if (this.survey.name.length > 0 && this.survey.use_type) {
                    this.createSurveyLoading = true
                    const res = await this.createSurvey();
                    this.createSurveyLoading = false

                    if(res && res.status=== 200){
                        this.$emit('close')
                        this.$router.push({
                            name: "dashboard.voiceform.show",
                            params: {
                                surveyId: this.survey.id,
                            },
                        });
                        this.surveyNamePopup = false;
                        if(res.data){
                            const cioData = {
                                form_id: res.data.id,
                                form_hash: res.data.hash,
                            }

                            if(this.templateId){
                                cioData.template_id = this.templateId
                            }


                            _cio.track("create_form.success", cioData)
                        }
                        this.showSnackBar({
                            color: "success",
                            timeout: 4000,
                            text: "Voiceform is created."
                        })
                    }
                } else {
                    return;
                }
            } catch (error) {
                this.createSurveyLoading = false;
                this.showSnackBar({
                    color: "error",
                    timeout: 6000,
                    text: "Cannot create survey."
                })
                console.error('handleCreateSurveyClick', error);
            }
            
        },

    },
    created(){
        let template;
        if (this.templateId) {
            template = templates.find(
                (template) => template.id === this.templateId
            );
        }
        this.setDefaultSurvey(template && template.survey);
        this.survey.name=""
        this.survey.use_type = null;
        this.surveyNamePopup = true;
    },
    props: ['templateId']
}
</script>