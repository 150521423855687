<template>
  <empty-layout>
    <v-container class="page">
      <navigation-back-button></navigation-back-button>
      <h4 class="text-h4 mb-6">Templates</h4>
      <v-row no-gutters>
        <v-col
          cols="12"
          sm="12"
          md="6"
          lg="4"
          class="pr-6"
        >
          <v-card
            class="mx-auto"
            max-width="404px"
            min-height="266px"
            outlined
            hover
            @click="handleSelection(null)"
          >
            <div height="162px" class="primary add-cover">
              <v-icon xLarge  color="white">
                mdi-plus-circle
              </v-icon>
            </div>

            <v-card-title>
              Start From Scratch
            </v-card-title>

            <v-card-subtitle>
              Create your new survey from scratch
            </v-card-subtitle>
          </v-card>
        </v-col>
        <template v-for="(template, i) in templates">
          <v-col
            :key="i"
            sm="12"
            md="6"
            lg="4"
            class="pr-6"
          >
            <v-card
              class="mx-auto"
              max-width="404px"
              min-height="266px"
              outlined
              hover
              @click="handleSelection(template.id)"
            >
              <v-img
                :src="template.image"
                :lazy-src="template.lazy"
                height="162px"
              ></v-img>

              <v-card-title>
                {{template.title}}
              </v-card-title>

              <v-card-subtitle>
                {{template.subtitle}}
              </v-card-subtitle>
            </v-card>
          </v-col>
        </template>
      </v-row>
      <v-dialog v-model="createFormDialog" width="400">
          <create-form-dialog @close="createFormDialog=false" :templateId="selectedTemplateId"></create-form-dialog>
      </v-dialog>
    </v-container>
  </empty-layout>
</template>

<script>
import templates from './templates'
import EmptyLayout from '../../../../layouts/EmptyLayout.vue';
import NavigationBackButton from '../../../../layouts/NavigationBackButton.vue';
import CreateFormDialog from '@/views/home/CreateFormDialog.vue';

export default {
  name: "Templates",
  props: {
    action: {
      type: String,
    }
  },
  components: {
    EmptyLayout,
    NavigationBackButton,
    CreateFormDialog
  },
  data() {
      return {
          templates,
          createFormDialog: false,
          selectedTemplateId: null
      };
  },
  methods: {
    handleSelection(templateId){
      this.selectedTemplateId=templateId
      this.createFormDialog=true
    }
  }

}
</script>

<style lang="scss" scoped>
  .page {
    padding: 0;
    .v-card {
      margin: 16px;
      // TODO: use typography
      &__title {
        font-size: 18px ;
        font-weight: 600;
        margin-bottom: 8px;
        line-height: 21.94px;
      }
      &__subtitle {
        font-size: 14px ;
        font-weight: 400;
        line-height: 17.07px;
      }
    }
  }
  .add-cover {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 162px;
    }
</style>